<template>
  <div>
    <!-- 标题 -->
    <!-- <div>
      <van-nav-bar title="资讯详情" />
    </div> -->
    <!-- 内容 -->
    <div class="essay">
      <div class="essayTitle">{{ list.Title }}</div>
      <div class="rikind">{{ list.TKName }}</div>
      <!-- <div v-if="list.ThematicUrl"
        class="essayImg">
        <img :src="list.ThematicUrl"
          alt="" />
      </div> -->
      <p class="essayContent" v-html="list.Content"></p>
      <div class="video" v-if="list.Video">
        <video style="width: 100%" :src="list.Video" controls="controls">
          您的浏览器不支持 video 标签。
        </video>
      </div>
      <div class="rikind" style="text-align: right">
        阅读：{{ list.ReadNum }}
      </div>
    </div>
  </div>
</template>
<script type="text/javascript" src="https://res.wx.qq.com/open/js/jweixin-1.3.2.js"></script>
<script>
import { WeGetRealInfo } from "@/api/RealInfo";
export default {
  data() {
    return {
      list: {},
      // pdfh5: null
    };
  },
  methods: {
    // 获取资讯详情
    getRealInfo() {
      WeGetRealInfo({ riId: this.$route.params.Id }).then((res) => {
        this.list = res.data.data;
        console.log(this.list);
        // this.list.Content = this.list.Content.replace(/<.*?>/ig,"")
        // console.log(this.list.Content);
        this.fenxiang(this.list);
      });
    },
    onShareAppMessage: function (options) {
      let return_url = options.webViewUrl; //分享的当前页面的路径
      return {
        desc: desc, //标题
        title: "分享内容",
        path: return_url, //分享地址
        success: function (res) {
          // 转发成功
          wx.showToast({
            title: "转发成功",
            icon: "success",
            duration: 2000,
          });
        },
        fail: function (res) {
          // 转发失败
        },
      };
    },
    fenxiang: function (row) {
      console.log(row);
      wx.miniProgram.postMessage({
        data: {
          title: row.Title,
          desc: row.RIKindDesc,
          imageUrl: row.ThematicUrl,
        },
      });
    },
  },
  mounted() {
    this.getRealInfo();
  },
};
</script>
<style>
.essay .essayContent img {
  display: block;
  width: 100%;
}
</style>